import { variantIsUnavailableGlobal } from "@app/globalStates/Product/GlobalState"
import { MenuItems } from "@temp/components/MainMenu/utils"
import { getDBIdFromGraphqlId, getGraphqlIdFromDBId } from "@temp/core/utils"

import { parse } from "query-string"
import {
  ProductDetails_product,
  ProductDetails_product_variants,
} from "@temp/@next/staticProps/product/types/ProductDetails"
import { CartLineInterface } from "@temp/@next/globalStates/Checkout/types"

interface ProductAttributeValue {
  id: string
  name: string
  value: string
}

interface ProductAttributeValueMap {
  [key: string]: ProductAttributeValue
}

export interface ProductAttribute {
  id: string
  name: string
  values: ProductAttributeValueMap
}
export interface ProductAttributeMap {
  [key: string]: ProductAttribute
}

export interface ProductVariantMap {
  [key: string]: string
}

export const menuHelper = (links: [], index) => {
  const safeIndex = index - 1
  const safeLinks = links.slice(0, safeIndex)

  const removedItems = links.slice(safeIndex)
  const moreMenuItem: MenuItems = {
    children: removedItems,
    id: "more",
    name: "More",
    parent: undefined,
    style: "normal",
  }
  safeLinks.push(moreMenuItem)
  return { safeLinks, moreMenuItem }
}

export const checkMenuOverFlow = (mainMenuReference) => {
  for (let index = 0; index < mainMenuReference.current.childNodes.length; index++) {
    const cords = mainMenuReference.current.childNodes[index].getBoundingClientRect()
    if (cords.x + cords.width > document.documentElement.clientWidth - 68) {
      return { overflow: true, index }
    }
  }
  return false
}

export const initiateCheckoutEvent = (checkout, isnearExpiryProduct) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const content_ids = []
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const content_type = "product"
  const contents = []
  let value = 0
  // eslint-disable-next-line @typescript-eslint/naming-convention
  let number_items = 0
  if (checkout && checkout.lines.length > 0) {
    for (const line of checkout.lines) {
      if (line.variation) {
        for (const variant of line.variation.variants) {
          const databaseId = getDBIdFromGraphqlId(
            variant.id,
            "ProductVariant"
          ).toString()
          content_ids.push(databaseId)
          contents.push({
            id: databaseId,
            quantity: line.quantity,
            isNearExpiry: isnearExpiryProduct,
          })
          value += variant.pricing.price.gross.amount * line.quantity
          number_items += line.quantity
        }
      } else {
        const databaseId = getDBIdFromGraphqlId(
          line.variant.id,
          "ProductVariant"
        ).toString()
        content_ids.push(databaseId)
        contents.push({
          id: databaseId,
          quantity: line.quantity,
          isNearExpiry: isnearExpiryProduct,
        })
        value += line.variant.pricing.price.gross.amount * line.quantity
        number_items += line.quantity
      }
    }
    const checkoutData = {
      content_ids,
      contents,
      content_type,
      currency: "INR",
      num_items: number_items,
      value,
    }
    return checkoutData
  }
  return ""
}

export const getVariantId = (path: string) => {
  const isComboProduct = path.includes("combo-product")
  const param = parse(path.split("?")[1])
  const variantParam = isComboProduct ? param.variation : param.variant
  if (variantParam) {
    const variantSlugs = variantParam.split("-")
    return getGraphqlIdFromDBId(
      variantSlugs[variantSlugs.length - 1],
      isComboProduct ? "ComboProductVariation" : "ProductVariant"
    )
  }
  return false
}

export const getOutOfStockVariantIds = (
  product: ProductDetails_product,
  lines: CartLineInterface[]
) => {
  const outOfStockVariantIds: string[] = []
  for (const variant of product!.variants!) {
    if (variant!.stockQuantity === 0) {
      outOfStockVariantIds.push(variant!.id)
    }
    if (variant!.stockQuantity === 1) {
      for (const line of lines) {
        if (line.variantId === variant!.id) {
          outOfStockVariantIds.push(variant!.id)
        }
      }
    }
  }
  return outOfStockVariantIds
}

export const getOutOfStockVariantIdsNearExpiry = (
  product: ProductDetails_product,
  lines: CartLineInterface[]
) => {
  const outOfStockVariantIds: string[] = []
  for (const variant of product!.variants!) {
    if (variant!.neStockQuantity === 0) {
      outOfStockVariantIds.push(variant!.id)
    }
    if (variant!.neStockQuantity === 1) {
      for (const line of lines) {
        if (line.variantId === variant!.id) {
          outOfStockVariantIds.push(variant!.id)
        }
      }
    }
  }
  return outOfStockVariantIds
}

export const createComboProductDescription = (
  variant,
  productAttributes: ProductAttributeMap,
  productVariantMap: ProductVariantMap,
  isCurrentVariantSelectedVariant,
  selectedVariantArray
) => {
  const keys = []
  for (const attribute of variant.attributes) {
    if (isCurrentVariantSelectedVariant) {
      selectedVariantArray.push(attribute.value.id)
    }

    // Creating variant map key
    keys.push(attribute.value.id)

    // Creating map for displaying all attributes on the UI
    if (!productAttributes[attribute.attribute.id]) {
      // eslint-disable-next-line no-param-reassign
      productAttributes[attribute.attribute.id] = {
        id: attribute.attribute.id,
        name: attribute.attribute.name,
        values: {},
      }
    }
    if (!productAttributes[attribute.attribute.id].values[attribute.value.id]) {
      // eslint-disable-next-line no-param-reassign
      productAttributes[attribute.attribute.id].values[attribute.value.id] = {
        id: attribute.value.id,
        name: attribute.value.name,
        value: attribute.value.value,
      }
    }
  }

  // Adding key to map
  // eslint-disable-next-line no-param-reassign
  productVariantMap[keys.join(",")] = variant.id
}

export const getAddToCartButtonText = (
  quantity: number,
  availableVariant: ProductDetails_product_variants,
  lines: CartLineInterface[],
  nearExpiry: boolean
) => {
  const variantIsUnavailable = variantIsUnavailableGlobal()
  if (variantIsUnavailable) {
    return "Not Available"
  }
  if (availableVariant && !nearExpiry) {
    if (availableVariant.stockQuantity === 0) {
      return "Out of stock"
    }
    const cartLine = lines.find(
      ({ variantId, isNearExpiry }) =>
        variantId === availableVariant.id && isNearExpiry === nearExpiry
    )
    const syncedQuantityWithCart = quantity
    if (
      !(
        quantity !== 0 &&
        availableVariant.id &&
        availableVariant.stockQuantity >= syncedQuantityWithCart
      )
    ) {
      if (quantity === 1) {
        return "Sold Out"
      }
    } else {
      if (cartLine && cartLine.quantity > 0) {
        return "Go to Cart"
      }
      return "Add to Cart"
    }
  }

  if (availableVariant && nearExpiry) {
    if (availableVariant.neStockQuantity === 0) {
      return "Out of stock"
    }
    const cartLine = lines.find(
      ({ variantId, isNearExpiry }) =>
        variantId === availableVariant.id && isNearExpiry === nearExpiry
    )
    const syncedQuantityWithCart = quantity
    if (
      !(
        quantity !== 0 &&
        availableVariant.id &&
        availableVariant.neStockQuantity >= syncedQuantityWithCart
      )
    ) {
      if (quantity === 1) {
        return "Sold Out"
      }
    } else {
      if (cartLine) {
        return "Go to Cart"
      }
      return "Add to Cart"
    }
  }
  return "Not Available"
}

export const selectVariantId = (product, variation) => {
  let variantId = ""
  for (const variant of variation.variants) {
    if (variant.product.id === product.id) {
      variantId = variant.id
    }
  }
  return variantId
}

export const productVariantHeader = (productId: string, variants) => {
  let name = ""
  for (const variant of variants) {
    if (variant.product.id === productId) {
      name = variant.name
    }
  }
  return name
}

// Combo Product Page utils
export const changeVariation = (
  variantId: string,
  productId: string,
  selectedVariant: number,
  variations
) => {
  const selectedVariation = variations[selectedVariant]
  const variantIds = {}
  for (const variant of selectedVariation.variants) {
    if (variant.product.id === productId) {
      variantIds[variantId] = 1
    } else {
      variantIds[variant.id] = 1
    }
  }
  let finalVariant = selectedVariant
  for (const [index, variation] of variations.entries()) {
    let c = 0
    for (const variant of variation.variants) {
      if (!variantIds[variant.id]) {
        c = 1
      }
    }
    if (c === 0) {
      finalVariant = index
    }
  }
  return finalVariant
}

export const errorRefresh = () => {
  window.location.reload()
}

export const timeSince = (date) => {
  const seconds = Math.floor((Date.now() - date) / 1000)

  let interval = seconds / 31_536_000

  if (interval > 2) {
    return `${Math.floor(interval)} years`
  }
  if (interval > 1) {
    return `${Math.floor(interval)} year`
  }
  interval = seconds / 2_592_000
  if (interval > 2) {
    return `${Math.floor(interval)} months`
  }
  if (interval > 1) {
    return `${Math.floor(interval)} month`
  }
  interval = seconds / 86_400
  if (interval > 2) {
    return `${Math.floor(interval)} days`
  }
  if (interval > 1) {
    return `${Math.floor(interval)} day`
  }
  interval = seconds / 3600
  if (interval > 2) {
    return `${Math.floor(interval)} hours`
  }
  if (interval > 1) {
    return `${Math.floor(interval)} hour`
  }
  interval = seconds / 60
  if (interval > 2) {
    return `${Math.floor(interval)} minutes`
  }
  if (interval > 1) {
    return `${Math.floor(interval)} minute`
  }
  return "Just Now"
}

export const timeSinceDays = (date) => {
  const seconds = Math.floor((Date.now() - date) / 1000)

  const interval = seconds / 86_400

  if (interval > 1) {
    return Math.floor(interval)
  }

  return "Just Now"
}

export const deepSearchByKey = (object, originalKey, matches = []) => {
  if (object != null) {
    if (Array.isArray(object)) {
      for (const arrayItem of object) {
        deepSearchByKey(arrayItem, originalKey, matches)
      }
    } else if (typeof object === "object") {
      for (const key of Object.keys(object)) {
        if (key === originalKey) {
          matches.push(object)
        } else {
          deepSearchByKey(object[key], originalKey, matches)
        }
      }
    }
  }

  return matches
}

export const flattenArray = (data) =>
  // eslint-disable-next-line unicorn/no-array-reduce
  data.reduce(function iter(r, a) {
    if (a === null) {
      return r
    }
    if (Array.isArray(a)) {
      // eslint-disable-next-line unicorn/no-array-reduce
      return a.reduce((accumulator, element) => iter(accumulator, element), r)
    }
    if (typeof a === "object") {
      return (
        Object.keys(a)
          .map((k) => a[k])
          // eslint-disable-next-line unicorn/no-array-reduce
          .reduce((accumulator, element) => iter(accumulator, element), r)
      )
    }
    return [...r, ...a]
  }, [])
